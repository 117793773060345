var BASE_API_URL = process.env.REACT_APP_API_URL;
var BASE_URL = process.env.REACT_APP_BASE_URL;


const API_URLS = {
  getVersioning: { method: 'GET', url: `${BASE_API_URL}/d/version` },
  loginGoogle: { method: 'POST', url: `${BASE_API_URL}/social-auth/google` },
  login: { method: 'POST', url: `${BASE_API_URL}/auth/login/` },
  reset: { url: '/reset', title: 'Reset', sidebar: false, navbar: false },
  logout: { method: 'POST', url: `${BASE_API_URL}/auth/logout/` },
  resetPassword: { method: 'POST', url: `${BASE_API_URL}/auth/password/code/reset/confirm/` },
  requestCodeResetPassword: { method: 'POST', url: `${BASE_API_URL}/auth/password/code/reset/` },
  changePassword: { method: 'POST', url: `${BASE_API_URL}/auth/password/change/` },
  getChatInitial: { method: 'GET', url: `${BASE_API_URL}/d/dentist/chat/initialization` },
  getProfile: { method: 'GET', url: `${BASE_API_URL}/d/dentist/profile` },
  getDentistProfile: { method: 'GET', url: `${BASE_API_URL}/d/dentist/profiles/{dentist_uid}` },
  getDentistClinicRoles: { method: 'GET', url: `${BASE_API_URL}/d/dentist/clinic_roles` },
  getAvatar: { method: 'GET', url: `${BASE_API_URL}/d/dentist/profile/photo` },
  getDentistRequestList: { method: 'GET', url: `${BASE_API_URL}/d/dentist/requests` },
  getOpenRequestList: { method: 'GET', url: `${BASE_API_URL}/opreq/openrequests` },
  acceptOpenRequest: {
    method: 'POST',
    url: `${BASE_API_URL}/opreq/openrequest/{requestId}/accept`,
  },
  acceptOpenRequestVideoChat: {
    method: 'POST',
    url: `${BASE_API_URL}/opreq/openrequest/{requestId}/videochat/accept`,
  },
  getDentistRequestVideoChatToken: {
    method: 'GET',
    url: `${BASE_API_URL}/d/dentist/request/{requestId}/chat_token`,
  },
  getDentistPatientList: { method: 'GET', url: `${BASE_API_URL}/d/dentist/patients` },
  createDentistPatient: { method: 'POST', url: `${BASE_API_URL}/d/dentist/patients` },
  updateDentistPatient: {
    method: 'POST',
    url: `${BASE_API_URL}/d/dentist/patient/uuid/{patient_uid}`,
  },
  getClinicBilling: {
    method: 'GET',
    url: `${BASE_API_URL}/d/clinics/clinic/billing`,
  },
  getDentistReferralCode: {
    method: 'GET',
    url: `${BASE_API_URL}/d/clinics/clinic/referralcode`,
  },
  getBookingClinicProfile: {
    method: 'GET',
    url: `${BASE_API_URL}/d/clinics/clinic/booking/profile`,
  },
  getBookingDentistProfile: {
    method: 'GET',
    url: `${BASE_API_URL}/d/clinics/dentist/booking/profile`,
  },
  updateBookingClinicProfile: {
    method: 'POST',
    url: `${BASE_API_URL}/d/clinics/clinic/booking/profile`,
  },
  updateBookingDentistProfile: {
    method: 'POST',
    url: `${BASE_API_URL}/d/clinics/dentist/booking/profile`,
  },
  getDentistPatientSearch: { 
    method: 'GET', 
    url: `${BASE_API_URL}/d/dentist/patients/search` 
  },
  getDentistPatientDetailId: {
    method: 'GET',
    url: `${BASE_API_URL}/d/dentist/patient/id/{patient_id}`,
  },
  getDentistPatientDetailUUid: {
    method: 'GET',
    url: `${BASE_API_URL}/d/dentist/patient/uuid/{patient_uuid}`,
  },
  getDentistPatientNotes: {
    method: 'GET',
    url: `${BASE_API_URL}/d/dentist/patient/uuid/{patient_uid}/notes`,
  },
  createPatientRecord: {
    method: 'POST',
    url: `${BASE_API_URL}/d/dentist/record`,
  }, 
  createPatientUpload: {
    method: 'POST',
    url: `${BASE_API_URL}/d/dentist/record/upload`,
  },
  createPatientRequest: {
    method: 'POST',
    url: `${BASE_API_URL}/d/dentist/requests`,
  },
  createDentistPatientNotes: {
    method: 'POST',
    url: `${BASE_API_URL}/d/dentist/patient/uuid/{patient_uid}/notes`,
  },
  updateDentistPatientNotes: { method: 'POST', url: `${BASE_API_URL}/d/dentist/notes/{note_uid}` },
  getDentistPatientAppointmentsId: {
    method: 'GET',
    url: `${BASE_API_URL}/d/dentist/patient/id/{patient_id}/appointments`,
  },
  getDentistPatientAppointmentsUUid: {
    method: 'GET',
    url: `${BASE_API_URL}/d/dentist/patient/uuid/{patient_uuid}/appointments`,
  },
  getDentistPatientRequestsId: {
    method: 'GET',
    url: `${BASE_API_URL}/d/dentist/patient/id/{patient_id}/requests`,
  },
  getDentistPatientRequestsUUid: {
    method: 'GET',
    url: `${BASE_API_URL}/d/dentist/patient/uuid/{patient_uuid}/requests`,
  },
  getDentistPatientRecordsId: {
    method: 'GET',
    url: `${BASE_API_URL}/d/dentist/patient/id/{patient_id}/records`,
  },
  getDentistPatientRecordsUUid: {
    method: 'GET',
    url: `${BASE_API_URL}/d/dentist/patient/uuid/{patient_uuid}/records`,
  },
  getDentistPatientReportsId: {
    method: 'GET',
    url: `${BASE_API_URL}/d/dentist/patient/id/{patient_id}/reports`,
  },
  getDentistPatientReportsUUid: {
    method: 'GET',
    url: `${BASE_API_URL}/d/dentist/patient/uuid/{patient_uuid}/reports`,
  },
  getDentistRequestDetail: {
    method: 'GET',
    url: `${BASE_API_URL}/d/dentist/request/{idType}/{requestId}`,
  },
  getDentistRequestRecordList: {
    method: 'GET',
    url: `${BASE_API_URL}/d/dentist/request/{requestId}/records`,
  },
  getDentistRequestRecordDetailUuid: {
    method: 'GET',
    url: `${BASE_API_URL}/d/dentist/record/uuid/{recordUid}`,
  },
  getDentistRequestRecordUpload: {
    method: 'GET',
    url: `${BASE_API_URL}/d/dentist/recordupload/{recordUploadUid}`,
  },
  getDentistRequestRecordUploadDetections: {
    method: 'GET',
    url: `${BASE_API_URL}/d/dentist/recordupload/{recordUploadUid}/detections`,
  },
  createDentistRequestRecordUploadDetections: {
    method: 'POST',
    url: `${BASE_API_URL}/d/dentist/recordupload/{recordUploadUid}/detections`,
  },
  getDentistRequestRecordDetail: {
    method: 'GET',
    url: `${BASE_API_URL}/d/dentist/record/id/{recordId}`,
  },
  getDentistRequestRecordDetailWithRequest: {
    method: 'GET',
    url: `${BASE_API_URL}/d/dentist/request/{requestId}/record/{recordId}`,
  },
  getDentistRequestReportList: {
    method: 'GET',
    url: `${BASE_API_URL}/d/dentist/request/{requestId}/reports`,
  },
  getDentistRequestReportDetail: {
    method: 'GET',
    url: `${BASE_API_URL}/d/dentist/report/{reportId}`,
  },
  getDentistRequestReportChartList: {
    method: 'GET',
    url: `${BASE_API_URL}/d/dentist/report/{reportId}/charts`,
  },
  getBulkReports: {
    method: 'GET',
    url: `${BASE_API_URL}/d/dentist/bulk/reports`,  
  },
  getDentistAppointments: { method: 'GET', url: `${BASE_API_URL}/d/dentist/appointments` },
  createDentistAppointments: { method: 'POST', url: `${BASE_API_URL}/d/dentist/appointments` },
  updateDentistAppointment: {
    method: 'POST',
    url: `${BASE_API_URL}/d/dentist/appointments/{appointmentUid}`,
  },
  getDentistAppointmentVideoChat: {
    method: 'GET',
    url: `${BASE_API_URL}/d/dentist/appointment/{requestUid}/videochat`,
  },
  getDentistAppointmentRoom: {
    method: 'GET',
    url: `${BASE_API_URL}/d/dentist/appointments/{apptUid}/room`,
  },
  getDentistAppointmentToken: {
    method: 'GET',
    url: `${BASE_API_URL}/d/dentist/appointments/{apptUid}/token`,
  },
  getDentistRequestReportChartDetail: {
    method: 'GET',
    url: `${BASE_API_URL}/d/dentist/report/{reportId}/charts/{reportChartId}`,
  },
  deleteDentistRequestReportChartDetail: {
    method: 'POST',
    url: `${BASE_API_URL}/d/dentist/report/{reportId}/charts/{reportChartId}/delete`,
  },
  getDentistRequestReportUploadChartDetail: {
    method: 'GET',
    url: `${BASE_API_URL}/d/dentist/report/{reportId}/uploads/{uploadId}`,
  },
  getDentistReportCategories: { method: 'GET', url: `${BASE_API_URL}/d/dentist/report/categories` },
  getDentistReportTemplates: { method: 'GET', url: `${BASE_API_URL}/d/dentist/report/templates` },
  uploadAvatar: { method: 'POST', url: `${BASE_API_URL}/d/dentist/profile/photo` },
  createRequestPartialScan: {
    method: 'POST',
    url: `${BASE_API_URL}/d/dentist/request/{requestId}/partialscans`,
  },
  createRequestReport: {
    method: 'POST',
    url: `${BASE_API_URL}/d/dentist/request/{requestId}/reports`,
  },
  requirePartialScan: {
    method: 'POST',
    url: `${BASE_API_URL}/d/dentist/request/{requestId}/partialscanstatus`,
  },
  updateRequestReport: { method: 'POST', url: `${BASE_API_URL}/d/dentist/report/{reportId}` },
  getReportRecommendations: { method: 'GET', url: `${BASE_API_URL}/d/dentist/report/{reportId}/recommendations` },
  createRequestReportChart: {
    method: 'POST',
    url: `${BASE_API_URL}/d/dentist/report/{reportId}/charts`,
  },
  updateRequestReportChart: {
    method: 'POST',
    url: `${BASE_API_URL}/d/dentist/report/{reportId}/charts/{reportChartId}`,
  },
  sendReport: {
    method: 'POST',
    url: `${BASE_API_URL}/d/dentist/report/{reportId}/send`,
  },
  uploadReport: {
    method: 'POST',
    url: `${BASE_API_URL}/d/dentist/report/{reportId}/upload`,
  },
  sendRequestReport: {
    method: 'POST',
    url: `${BASE_API_URL}/d/dentist/request/{requestId}/reports/send`,
  },
  fetchChatRecord: {
    method: 'GET',
    url: `${BASE_API_URL}/d/dentist/chat/{patient}/record/{recordId}`,
  },
  getDentistSelectOptions: { method: 'GET', url: `${BASE_API_URL}/d/select_options/{select_option}` },
  getAdminSelectOptions: { method: 'GET', url: `${BASE_API_URL}/a/select_options/{select_option}` },
  searchDentistUsers: { method: 'GET', url: `${BASE_API_URL}/d/dentist/search` },
  getDentistDetailsAdmin: { method: 'GET', url: `${BASE_API_URL}/a/dentists/{dentistId}` },
  getDentistDetailsRolesAdmin: { method: 'GET', url: `${BASE_API_URL}/a/dentists/{dentistId}/roles` },
  createDentistRoleAdmin: { method: 'POST', url: `${BASE_API_URL}/a/dentists/{dentistId}/roles` },
  editDentistAdmin: { method: 'POST', url: `${BASE_API_URL}/a/dentists/{dentistId}` },
  getUserProfiles: { method: 'GET', url: `${BASE_API_URL}/a/users/{userId}/user_profiles` },
  createUserProfile:  { method: 'POST', url: `${BASE_API_URL}/a/users/{userId}/user_profiles` },
  getUserFavoriteDentists: { method: 'GET', url: `${BASE_API_URL}/a/users/{userId}/favorite_dentists`},
  createUserFavoriteDentist: { method: 'POST', url: `${BASE_API_URL}/a/users/{userId}/favorite_dentists`},
  createUserUploads:  { method: 'POST', url: `${BASE_API_URL}/a/users/{userId}/uploads` },
  createUserRecord:  { method: 'POST', url: `${BASE_API_URL}/a/users/{userId}/records` },
  getUserDetailsAdmin: { method: 'GET', url: `${BASE_API_URL}/a/users/{userId}` },
  editUserAdmin: { method: 'POST', url: `${BASE_API_URL}/a/users/{userId}` },
  getAdminClinicCollection: { method: 'GET', url: `${BASE_API_URL}/a/clinics` },
  getAdminUserCollection: { method: 'GET', url: `${BASE_API_URL}/a/dentists` },
  getAdminPatientUserCollection: { method: 'GET', url: `${BASE_API_URL}/a/users` },
  getAdminUserRequestCollection: { method: 'GET', url: `${BASE_API_URL}/a/user_requests` },
  editReportTemplate: { method: 'POST', url: `${BASE_API_URL}/a/report/templates/{id}` },
  getAdminReportTemplateDetails: { method: 'GET', url: `${BASE_API_URL}/a/report/templates/{id}` },
  createReportTemplate: { method: 'POST', url: `${BASE_API_URL}/a/report/templates` },
  getAdminReportTemplateCollection: { method: 'GET', url: `${BASE_API_URL}/a/report/templates` },
  createAccountRoleAdmin: { method: 'POST', url: `${BASE_API_URL}/a/clinics/{clinicId}/roles` },
  getClinicDetailsRolesAdmin: { method: 'GET', url: `${BASE_API_URL}/a/clinics/{clinicId}/roles` },
  getClinicDetailsAdmin: { method: 'GET', url: `${BASE_API_URL}/a/clinics/{clinicId}` },
  editClinicAdmin: { method: 'POST', url: `${BASE_API_URL}/a/clinics/{clinicId}` },
  getUserRequestDetailsAdmin: { method: 'GET', url: `${BASE_API_URL}/a/user_requests/{requestId}` },
  editUserRequestAdmin: { method: 'POST', url: `${BASE_API_URL}/a/user_requests/{requestId}` },
  createUserRequestAdmin: { method: 'POST', url: `${BASE_API_URL}/a/user_requests` },
  createClinicAdmin: { method: 'POST', url: `${BASE_API_URL}/a/clinics` },
  createPatientUserAdmin: { method: 'POST', url: `${BASE_API_URL}/a/users` },
  createAccountAdmin: { method: 'POST', url: `${BASE_API_URL}/a/dentists` },
  searchAdminClinics: { method: 'GET', url: `${BASE_API_URL}/a/search/clinics`},
  searchAdminDentists: { method: 'GET', url: `${BASE_API_URL}/a/search/dentists`},
  searchAdminUsers: { method: 'GET', url: `${BASE_API_URL}/a/search/users`}, 
  searchAdminUserProfiles: { method: 'GET', url: `${BASE_API_URL}/a/search/user_profiles`}
};

const URLS = {
  home: { url: '/', title: 'Home' },
  test: { url: '/test', title: 'Test1' },
  test2: { url: '/test2', title: 'Test2', sidebar: false, navbar: false },
  login: { url: '/login', title: 'Login', sidebar: false, navbar: false },
  reset: { url: '/reset', title: 'Reset', sidebar: false, navbar: false },
  forgotPassword: { url: '/forgot', title: 'Forgot Password', sidebar: false, navbar: false },
  requestList: { url: '/requests', title: 'Requests' },
  chatList: { url: '/chats', title: 'Chats' },
  openRequestsPage: { url: '/openrequests', title: 'Open Requests' },
  requestPage: { url: '/request/{requestId}', title: 'Request' },
  reportRequestPage: { url: '/reports/request/{requestId}', title: 'Report Request' },
  patientList: { url: '/patients', title: 'Patients' },
  patientPage: { url: '/patients/{patientId}', title: 'Patient' },
  settings: { url: '/settings', title: 'Settings' },
  adminPage: { url: '/adm/collections/', title: 'Admin' },
  noMatch: { url: '/noMatch', title: 'Not Found', sidebar: false },
  videoChat: { url: '/videochat', title: 'Video Chat', navbar: false, sidebar: false },
};

// OPENTOK
var OPENTOK_API_KEY = process.env.REACT_APP_DAILYCO_API_KEY;
//var DAILYCO_API_KEY = process.env.REACT_APP_DAILYCO_API_KEY

const GRADES = [
  { label: 'NEGATIVE', value: 1, color: '#E8686D', bgColor: '#FFEDED' },
  { label: 'NEUTRAL', value: 2, color: '#E9B54D', bgColor: '#FDF8E2' },
  { label: 'POSITIVE', value: 3, color: '#43AC72', bgColor: '#E9F9F1' },
];

const REPORT_TYPES = [
  { label: 'Orthodontics', value: 'ORTHO', color: '#00574F' }, 
  { label: 'Oral Habits', value: 'HABITS', color: '#DAFF00' }, 
  { label: 'Plaque and Scaling', value: 'GUMS', color: '#FFA500' },
  { label: 'Cavities and Tooth Reconstruction', value: 'CARIES', color: '#DC4E41' },
  { label: 'Color and Aesthetic', value: 'COLOR', color: '#367FFF' },
  { label: 'Other', value: 'OTHERS', color: '#367FFF' },
];

// DECAYED, MISSING, FILLED

const REPORT_TYPES_DMFT = [
  { label: 'Orthodontics', value: 'ORTHO', color: '#00574F' }, 
  { label: 'Oral Habits', value: 'HABITS', color: '#DAFF00' }, 
  { label: 'Plaque and Scaling', value: 'GUMS', color: '#FFA500' },
  { label: 'Cavities and Tooth Reconstruction', value: 'CARIES', color: '#DC4E41' },
  { label: 'Color and Aesthetic', value: 'COLOR', color: '#367FFF' },
  { label: 'Other', value: 'OTHERS', color: '#367FFF' },
];

const LANGUAGE_LIST = [
  { key: 'en', label: 'English' },
  { key: 'ja', label: '日本' },
  { key: 'th', label: 'ไทย' },
];

const MAIN_SCROLL_CSS_CLASS_NAME = 'custom-scroll';
const PAGE_SIZE = 20;
const MOMENT_DATE_FORMAT = 'L';
const API_DATE_FORMAT = 'YYYY-MM-DD';
const API_DATETIME_FORMAT = 'YYYY-MM-DD HH:mm';

const TOOTH_NUMBERING_SYSTEM = {
  palmer: {
    t11: '11',
    t12: '12',
    t13: '13',
    t14: '14',
    t15: '15',
    t16: '16',
    t17: '17',
    t18: '18',

    t21: '21',
    t22: '22',
    t23: '23',
    t24: '24',
    t25: '25',
    t26: '26',
    t27: '27',
    t28: '28',

    t31: '31',
    t32: '32',
    t33: '33',
    t34: '34',
    t35: '35',
    t36: '36',
    t37: '37',
    t38: '38',

    t41: '41',
    t42: '42',
    t43: '43',
    t44: '44',
    t45: '45',
    t46: '46',
    t47: '47',
    t48: '48',
  },
  international: {
    t11: '8',
    t12: '7',
    t13: '6',
    t14: '5',
    t15: '4',
    t16: '3',
    t17: '2',
    t18: '1',

    t21: '9',
    t22: '10',
    t23: '11',
    t24: '12',
    t25: '13',
    t26: '14',
    t27: '15',
    t28: '16',

    t31: '24',
    t32: '23',
    t33: '22',
    t34: '21',
    t35: '20',
    t36: '19',
    t37: '18',
    t38: '17',

    t41: '25',
    t42: '26',
    t43: '27',
    t44: '28',
    t45: '29',
    t46: '30',
    t47: '31',
    t48: '32',
  },
};

const DEFAULT_ALGO_VER = '0.1.0'
const DEFAULT_ALGO_TYPE = 'caries'

const REGEX_ONLY_TOOTH = /t[0-9]{1,2}(?:(?=(o|bm|bd|ld|lm|l|b){1}$))/g;
const REGEX_ONLY_REGION = /(o|bm|bd|ld|lm|l|b){1}/g;

const REQUEST_TYPES = {
  REPORT: 'REPORT',
  VIDEOCHAT: 'VIDEOCHAT',
};
const RECORD_TYPES = {
  FULLSCAN: 'FULLSCAN',
  SELF: 'SELF',
  PARTIALSC: 'PARTIALSC',
  DETECTIONSC: 'DETECTIONSC',
  SINGLE: 'SINGLE',
  ATTACHMENT: 'ATTACHMENT',
};
const GENDERS = ['MALE', 'FEMALE', 'OTHER'];
export {
  BASE_URL,
  BASE_API_URL,
  API_URLS,
  GENDERS,
  OPENTOK_API_KEY,
  URLS,
  DEFAULT_ALGO_VER,
  DEFAULT_ALGO_TYPE,
  MAIN_SCROLL_CSS_CLASS_NAME,
  PAGE_SIZE,
  MOMENT_DATE_FORMAT,
  API_DATE_FORMAT,
  API_DATETIME_FORMAT,
  TOOTH_NUMBERING_SYSTEM,
  REGEX_ONLY_TOOTH,
  REGEX_ONLY_REGION,
  RECORD_TYPES,
  REQUEST_TYPES,
  GRADES,
  REPORT_TYPES,
  REPORT_TYPES_DMFT,
  LANGUAGE_LIST,
};
